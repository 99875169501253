<template>

  <div class="col-12 p-1 mb-4">

    <Card>
      <template #title>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 class="m-0">Identificação do Contribuinte</h5>
        </div>
      </template>
      <template #content>
        <div class="row">
          <div class="col-12 col-md-3 p-1">
            <span><b>Nome: </b></span>
            <span>{{ contribuinteNome }}</span>
          </div>
          <div class="col-12 col-md-3 p-1">
            <span><b>Documento: </b></span>
            <span>{{ contribuinteDocumento }}</span>
          </div>
          <div class="col-12 col-md-3 p-1">
            <span><b>Endereco: </b></span>
            <span>{{`${endereco.logradouro.tipoLogradouro.nome}  ${endereco.logradouro.nome}`}}</span>
          </div>
          <div class="col-12 col-md-3 p-1">
            <span><b>Cep: </b></span>
            <span>{{ endereco.cep }}</span>
          </div>
          <div class="col-12 col-md-3 p-1">
            <span><b>Bairro: </b></span>
            <span>{{ endereco.bairro.nome }}</span>
          </div>
          <div class="col-12 col-md-3 p-1">
            <span><b>Município: </b></span>
            <span>{{ endereco.localidade.nome }}</span>
          </div>
          <div class="col-12 col-md-3 p-1">
            <span><b>Estado: </b></span>
            <span>{{ endereco.localidade.estado.sigla }}</span>
          </div>
          <div class="col-12 col-md-3 p-1">
            
          </div>
        </div>
      </template>
    </Card>
  
  </div>

  <div class="col-12 p-1">

    <DataTable
      :loading="loading"
      paginator
      showGridlines
      dataKey="pid"
      ref="tableDam"
      class="p-datatable-customers"
      :value="damList"
      :rows="100"
      :rowHover="true"
      v-model:filters="filters"
      filterDisplay="menu"
      responsiveLayout="scroll"
    >
      <template #loading>
        Carregando...
      </template>

      <template #header>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 class="m-0">{{ modeloDam.tipoFiltro || "" }}</h5>
        </div>
      </template>

      <Column field="actionsButton" header="Ações" bodyStyle="text-align: center">
        <template #body="slotProps">
          <Button
            type="button"
            icon="pi pi-print"
            title="Imprimir"
            @click="imprimirDam(slotProps.data)"
            class="p-button-sm p-button-grid"
          />
        </template>
      </Column>

      <Column field="pinscricao" header="Inscrição" bodyStyle="text-align: center" sortable>
        <template #body="{ data }"> {{ data.pinscricao }} </template>
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Pesq. Inscrição"
          />
        </template>
      </Column>

      <Column field="pano" header="Ano" bodyStyle="text-align: center" sortable>
        <template #body="{ data }"> {{ data.pano }} </template>
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Pesq. Ano"
          />
        </template>
      </Column>

      <Column field="pmovimento" header="Agrupamento" sortable>
        <template #body="{ data }"> {{ data.pmovimento }} </template>
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Pesq. Agrupamento"
          />
        </template>
      </Column>

      <Column field="pparcela" header="Parcela" bodyStyle="text-align: center" sortable>
        <template #body="{ data }"> {{ data.pparcela }} </template>
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Pesq. Parcela"
          />
        </template>
      </Column>

      <Column field="psituacao" header="Situação" sortable>
        <template #body="{ data }"> {{ data.psituacao }} </template>
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Pesq. Situação"
          />
        </template>
      </Column>

      <Column field="pdatavencdam" header="Data de Vencimento" bodyStyle="text-align: center" sortable>
        <template #body="{ data }"> {{ data.dataVencFormatada }} </template>
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Pesq. Vencimento"
          />
        </template>
      </Column>

      <Column field="pvalor" header="Valor" bodyStyle="text-align: right;" sortable>
        <template #body="slotProps">
          {{ slotProps.data.valorFormatado }}
        </template>
      </Column>

      <Column field="pmulta" header="Multa" bodyStyle="text-align: right;" sortable>
        <template #body="slotProps">
          {{ slotProps.data.multaFormatado }}
        </template>
      </Column>

      <Column field="pjuros" header="Juros" bodyStyle="text-align: right;" sortable>
        <template #body="slotProps">
          {{ slotProps.data.jurosFormatado }}
        </template>
      </Column>

      <Column field="pcorrecao" header="Correção" bodyStyle="text-align: right;" sortable>
        <template #body="slotProps">
          {{ slotProps.data.correcaoFormatado }}
        </template>
      </Column>

      <Column field="pvalortotal" header="Total" bodyStyle="text-align: right;" sortable>
        <template #body="slotProps">
          {{ slotProps.data.valorTotalFormatado }}
        </template>
      </Column>

    </DataTable>
  </div>

  <div class="col-12 d-flex justify-content-end p-1">
    <Button
      label="Nova Pesquisa"
      type="button"
      class="p-button-primary"
      @click="$emit('novaPesquisa');"
      icon="pi pi-plus"
    />
  </div>

</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import { PortalService, ExtratoService } from "@/services";

export default {

  props: {
    modeloDam: {
      type: Object,
      default: () => {},
    },
    damList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      fields: {},

      enderecosPessoa: [],

      endereco: {
        cep: '',
        numero: '',
        enderecoTipo: '',
        dataVigenciaFinal: '',
        logradouro: {
          nome: '',
          cep: '',
          tipoLogradouro: {
            nome: '',
            sigla: '',
          },
        },
        bairro: {
          nome: '',
        },
        localidade: {
          estado: {
            nome: '',
            sigla: '',
          },
        },
      },

    };
  },

  async mounted() {
    await this.fetchEnderecos();
  },

  computed: {
    serverExec() {
      return this.$store.getters.getServerExec;
    },
    possuiDados() {
      return this.damList.length > 0;
    },
    idPessoa() {
      return this.possuiDados ? this.damList[0].pidpessoa : null;
    },
    contribuinteNome() {
      return this.possuiDados ? this.damList[0].contribuintenome : '';
    },
    contribuinteDocumento() {
      return this.possuiDados ? this.damList[0].contribuintedocumento : '';
    },
    filters() {
      return {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      }
    }
  },

  methods: {

    async fetchEnderecos() {
      try {
        this.$store.dispatch('SET_ENABLE_SPINNER', true);
        this.enderecosPessoa = await PortalService.getEnderecosByIdPessoa(this.idPessoa);

        this.endereco = JSON.parse(JSON.stringify(this.enderecosPessoa.filter(v => v.enderecoTipo === 'Domiciliar')[0]));
        if (this.endereco.enderecoTipo === null) {
          this.endereco = JSON.parse(JSON.stringify(this.enderecosPessoa.filter(v => v.enderecoTipo === 'Correspondência')[0]));
        } else if (this.endereco.enderecoTipo === null) {
          this.endereco = JSON.parse(JSON.stringify(this.enderecosPessoa.filter(v => v.enderecoTipo === 'Cobrança')[0]));
        }else if (this.endereco.enderecoTipo === null) {
          this.endereco = JSON.parse(JSON.stringify(this.enderecosPessoa.filter(v => v.enderecoTipo === 'EeL')[0]));
        }

      } catch (errorMessage) {
        console.log(errorMessage);
        this.$toast.add({
          severity: "error",
          summary: "Houve um problema ao buscar endereço!",
          life: 3000,
        });
      } finally {
        this.$store.dispatch('SET_ENABLE_SPINNER', false);
      }
    },

    async imprimirDam(data) {
      try {
        this.$store.dispatch('SET_ENABLE_SPINNER', true);

        await this.$root.getTrbToken;
        
        const imprimirDam = {
          idCalculoParcelados: data.pid,
          token: this.$store.getters.getTrbToken,
        };
        await ExtratoService.imprimirDam(this.serverExec,imprimirDam);

      } catch (errorMessage) {
        console.log(errorMessage);
        this.$toast.add({
          severity: "error",
          summary: "Houve um problema ao imprimir DAM!",
          life: 3000,
        });
      } finally {
        this.$store.dispatch('SET_ENABLE_SPINNER', false);
      }

    },

  }

};
</script>