<template>

  <div class="container" v-if="!possuiDados">

    <div class="row justify-content-center">
      <div class="col-md-4 p-1">
        <label>DAM:</label>
        <span class="p-fluid">
          <Dropdown
            v-model="modeloDam"
            :options="modelosDam"
            optionLabel="tipoFiltro"
            placeholder="Selecione o DAM"
            @change="findTipoFiltro"
          />
        </span>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-md-2 p-1" :class="hasContribuinteTipos ? 'col-md-2' : 'col-md-4'">
        <label for="tipoTomador">Filtro:</label>
        <span class="p-fluid">
          <Dropdown
            v-model="selectedFiltro"
            :options="filtrosConfiguracao"
            optionLabel="tipoFiltroDescricao"
            placeholder="Selecione um Filtro"
            @change="findContribuinteTipo"
          />
        </span>
      </div>

      <div class="col-12 col-md-2 p-1" v-if="hasContribuinteTipos">
        <label for="tipoTomador">{{
          selectedFiltro && selectedFiltro.tipoFiltro
            ? `Tipo de ${formatText(selectedFiltro.tipoFiltro)}:`
            : "Tipo:"
          }}
        </label>
        <span class="p-fluid">
          <Dropdown
            v-model="contribuinteTipo"
            :options="contribuinteTipos"
            optionLabel="descricaoContribuinteTipo"
            placeholder="Selecione um Tipo"
          />
        </span>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-md-4 p-1">
        <label for="tipoTomador">{{ labelInputDocumento }}</label>
        <span class="p-fluid" v-if="tipoFiltroSelected === 'CNPJ'">
          <InputMask
            @blur="validarDocumento()"
            v-model="filtro"
            key="CNPJ"
            mask="99.999.999/9999-99"
            placeholder="Informe o CNPJ"
          />
        </span>

        <span class="p-fluid" v-else-if="tipoFiltroSelected === 'INSCRIÇÃO'">
          <InputText
            v-model="filtro"
            key="INSCRICAO"
            placeholder="Informe a Inscrição Municipal"
            maxlength="20"
          />
        </span>

        <span class="p-fluid" v-else-if="tipoFiltroSelected === 'CPF' || tipoFiltroSelected === 'CPF_INSCRIÇÃO'">
          <InputMask
            @blur="validarDocumento()"
            v-model="filtro"
            key="CPF"
            mask="999.999.999-99"
            placeholder="Informe o CPF"
          />
        </span>

        <span class="p-fluid" v-else-if="tipoFiltroSelected === 'CODIGO_PARCELAMENTO'">
          <InputText
            v-model="filtro"
            key="CODIGO_PARCELAMENTO"
            placeholder="Informe o Código do Parcelamento"
            maxlength="20"
          />
          <!-- <InputMask
            v-model="filtro"
            key="CODIGO_PARCELAMENTO"
            mask="***********"
            placeholder="Informe o Código do Parcelamento"
          /> -->
        </span>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-4 p-1 mb-3">
        <label>Forma de pagamento:</label>
        <span class="p-fluid">
          <Dropdown
            v-model="formaPagamento"
            :options="formasPagamento"
            optionLabel="name"
            optionValue="value"
            placeholder="Selecione a forma de pagamento"
          />
        </span>
      </div>
    </div>

    <div class="row justify-content-center align-items-end">
      <div class="col-md-4 mb-3 d-flex justify-content-center">
        <span class="p-fluid">
          <vue-recaptcha
            ref="recaptcha"
            :sitekey="$store.getters.getKeyRecaptcha"
            @verify="handleSuccess"
            @expired="handleError"
            @render="handleError"
            @error="handleError"
          />
        </span>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-md-4 mb-3 p-1 d-flex justify-content-end">
        <Button
          label="Consultar"
          class="p-button-primary"
          @click="pesquisar"
          icon="pi pi-print"
          :disabled="captchaState"  
        />
      </div>
    </div>

  </div>

  <div class="row justify-content-center" v-if="exibirInfoDados">
    <div class="col-12 col-md-4 mb-3 p-1">
      <Message
        severity="info"
        :closable="false"
      >
        Sem dados para exibição!
      </Message>
    </div>
  </div>

  <div class="row">
    <div v-if="possuiDados">
      <GridDam :damList="damList" :modeloDam="modeloDam" @novaPesquisa="novaPesquisa" />
    </div>
  </div>

</template>
<script>

import moment from "moment";
import Util from "@/utils/util";
import { FilterMatchMode } from 'primevue/api';
import GridDam from "@/views/dam/GridDam";
import { DamService, PortalService } from "@/services";
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  components: { 
    VueRecaptcha,
    GridDam,
  },

  data() {
    return {
      loading: false,
      captchaState: true,
      pesq: true,
      exibirInfoDados: false,
      
      
      modeloDam: null,
      modelosDam: [],
      selectedFiltro: null,
      filtrosConfiguracao: [],
      filtro: '',

      contribuinteTipo: '',
      contribuinteTipos: [],

      formaPagamento: 'T',
      formasPagamento: [
        { value: "T", name: "Todas" },
        { value: "U", name: "Única" },
        { value: "P", name: "Parcelas" }
      ],

      damList: [],
    };
  },

  watch: {
    idFuncionalidade: {     
      handler() {
        if(this.funcionalidade.pagina === 'dam'){
          this.carregarDados();
        }
      },
      immediate: true
    }
  },

  created() {
    this.carregarDados();
  },

  computed: {
    funcionalidades() {
      return JSON.parse(JSON.stringify([...this.$root.menuFuncionalidades]));
    },
    funcionalidade() {
      const funcionalidadeFiltered = this.funcionalidades.filter(v => 
        v.nomeFuncionalidade && v.nomeFuncionalidade !== null 
        && v.nomeFuncionalidade === this.$store.getters.getMenuSelected
      )

      return  funcionalidadeFiltered[0] ? funcionalidadeFiltered[0] : null;
    },
    idFuncionalidade() {
      /* const funcionalidadesFiltered = this.funcionalidades.filter(v => 
        v.pagina && v.pagina !== null 
        && v.pagina.toLowerCase() === "dam"
      ) */

      const funcionalidadesFiltered = this.funcionalidades.filter(v => 
        v.nomeFuncionalidade && v.nomeFuncionalidade !== null 
        && v.nomeFuncionalidade === this.$store.getters.getMenuSelected
      )

      if(this.$route.query.idFunc) {
        return this.$route.query.idFunc;
      }
      console.log( funcionalidadesFiltered[0] ? funcionalidadesFiltered[0].id : null)
      return  funcionalidadesFiltered[0] ? funcionalidadesFiltered[0].id : null;
    },
    idCliente() {
      return this.$store.getters.getIdCliente;
    },
    idUsuario() {
      return this.$store.getters.getIdUsuario;
    },
    hasContribuinteTipos() {
      return  this.contribuinteTipos.length > 0;
    },
    tipoFiltroSelected() {
      return this.selectedFiltro && this.selectedFiltro.tipoFiltro;
    },
    labelInputDocumento() {
      if(this.tipoFiltroSelected) {
        const tipoFiltro = this.tipoFiltroSelected;

        if(tipoFiltro === 'CPF' || tipoFiltro === 'CPF_INSCRIÇÃO') return 'CPF';
        if(tipoFiltro === 'CNPJ') return 'CNPJ';
        if(tipoFiltro === 'INSCRIÇÃO') return 'Inscrição Municipal';
        if(tipoFiltro === 'CODIGO_PARCELAMENTO') return 'Código Parcelamento';
      }
      
      return '';
    },
    possuiDados() {
      return this.damList.length > 0;
    },
    filters() {
      return {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      }
    }
  },

  methods: {

    async carregarDados() {
      await this.$root.fetchFuncionalidades();
      await this.fetchModelosDam();
    },

    async fetchModelosDam() {
      try {
        this.$store.dispatch('SET_ENABLE_SPINNER', true);
        this.modelosDam = await DamService.findOrigemCalculo(this.idCliente, this.idFuncionalidade);
      } catch (errorMessage) {
        console.log(errorMessage);
        this.alerta("error", "Houve um problema ao listar DAM!");
      } finally {
        this.modeloDam = this.modelosDam[0];
        this.findTipoFiltro({value: this.modeloDam});
        this.$store.dispatch('SET_ENABLE_SPINNER', false);
      }
    },

    async findTipoFiltro(event) {
      try {
        const idModeloDam =  event.value && event.value.id;
        this.$store.dispatch('SET_ENABLE_SPINNER', true);
        const result = await DamService.findTipoFiltroDam(this.idCliente, this.idFuncionalidade, idModeloDam);
        this.filtrosConfiguracao = result.map(v => {
          if (v.tipoFiltro === "CPF_INSCRIÇÃO" ) return ({...v, tipoFiltroDescricao: 'CPF / INSCRIÇÃO'});
          if (v.tipoFiltro === "CODIGO_PARCELAMENTO" ) return ({...v, tipoFiltroDescricao: 'CÓDIGO DO PARCELAMENTO'});
          return ({...v, tipoFiltroDescricao: v.tipoFiltro});
        })
      } catch (errorMessage) {
        console.log(errorMessage);
        this.alerta("error", "Houve um problema ao listar os tipos de filtro!");
      } finally {
        this.selectedFiltro = this.filtrosConfiguracao[0];
        this.findContribuinteTipo({value: this.selectedFiltro});
        this.$store.dispatch('SET_ENABLE_SPINNER', false);
      }
    },

    async findContribuinteTipo(event) {
      try {
        const tipoFiltro = event.value && event.value.tipoFiltro;
        this.$store.dispatch('SET_ENABLE_SPINNER', true);
        this.contribuinteTipos = await PortalService.findFuncionalidadeContribuinteTipo(this.idCliente, this.idFuncionalidade, tipoFiltro);
      } catch (errorMessage) {
        console.log(errorMessage);
        this.alerta("error", "Houve um problema ao listar as filtros!");
      } finally {
        this.contribuinteTipo = this.contribuinteTipos.length > 0 ? this.contribuinteTipos[0] : '';
        this.$store.dispatch('SET_ENABLE_SPINNER', false);
      }
    },

    async pesquisar() {
      try {
        this.$store.dispatch('SET_ENABLE_SPINNER', true);

        const consultarDam = {
          idCliente: this.idCliente,
          idOrigemCalculo: this.modeloDam.idOrigemCalculo,
          filtro: this.filtro,
          idConfigPortal: this.selectedFiltro.id,
          filtroParcela: this.formaPagamento,
          vencimento: moment().format("YYYY-MM-DD"),
        };

        this.damList = await DamService.findAll(consultarDam);

      } catch (errorMessage) {
        console.log(errorMessage);
        this.alerta("error", "Houve um problema ao listar os DAM's!");
      } finally {
        if (this.damList.length > 0) {
          this.exibirInfoDados = false;
          this.pesq = false;
        } else {
          this.exibirInfoDados = true;
        }
        this.$store.dispatch('SET_ENABLE_SPINNER', false);
      }
    },

    handleSuccess() {
      this.captchaState = false;
    },

    handleError() {
      this.captchaState = true;
    },

    validarDocumento() {
      var validar = false;
      validar = Util.validarCPFCNPJ(this.documento);

      if (!validar && this.documento) {
        this.$root.$emit("alert:warn", "CPF/CNPJ " + this.documento + " inválido!");
        this.documento = "";
      }

      return validar;
    },

    formatText(text) {
      const textReturn = text === 'CPF_INSCRIÇÃO' ? 'Inscrição' : text;
      const lowerCase = textReturn.toLowerCase();
      return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
    },

    novaPesquisa() {
      this.damList = [];
      if (this.$refs.recaptcha) {
        this.$refs.recaptcha.reset();
      }
      this.exibirInfoDados = false;
    },

    alerta(type, msg) {
      this.$toast.add({
        severity: type,
        summary: msg,
        life: 3000,
      });
    },

  },

};
</script>